import VideoHolder from './video-holder';

window.onload = function() {
	const videoHolder = new VideoHolder(document.querySelector('.video-holder'));

	setInterval(function() {
		videoHolder.dispatchColor();
	}, 15000);

	document.querySelector('.player-overlay').addEventListener('click', function(e) {
		let overlay = document.querySelector('.player-overlay');
		overlay.parentNode.removeChild(overlay);
		videoHolder.begin();
	});

	document.querySelectorAll('.full-screen').forEach(item => {
		item.addEventListener('click', function(e) {
			e.preventDefault();
			videoHolder.fullscreen();
		});
	});

	document.querySelectorAll('.overlay').forEach(item => {
		item.addEventListener('click', function() {
			videoHolder.setActiveVideo(this.parentElement.parentElement);
		});
	});

	document.querySelectorAll('.volume-range').forEach(item => {
		item.addEventListener('change', function() {
			videoHolder.setVolume(this.value);
		});
	});

	document.querySelectorAll('.volume-toggle').forEach(item => {
		item.addEventListener('click', function(e) {
			e.preventDefault();
			videoHolder.toggleMute();
		});
	});
};
