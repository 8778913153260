const axios = require('axios');
const { debounce } = require('lodash');
const Cookies = require('js-cookie');
const uuid = require('uuid');
const BigScreen = require('bigscreen');
import Player from '@vimeo/player';

class VideoHolder {
	constructor(container) {
		this.defaultVolume = 100;
		this.volume = 0;
		this.begun = false;
		this.muted = false;
		this.container = container;
		this.uuid = Cookies.get('uuid') || uuid.v4();
		Cookies.set('uuid', this.uuid, { expires: 7 });

		BigScreen.onchange = element => this._fullScreenChange(element);

		this.debouncedDispatchColor = debounce(this._dispatchColor, 800);
		this.activeRandomVideo(container);
		this.setActiveVideo(container.querySelector('.video.active'), true);
	}

	activeRandomVideo(container) {
		let videos = container.querySelectorAll('.video'),
			random = Math.floor(Math.random() * videos.length);

		if (container.querySelector('.video.active') !== null) {
			container.querySelector('.video.active').classList.remove('active');
		}
		videos[random].classList.add('active');
	}

	setActiveVideo(holder, forceDispatch) {
		if (holder == this.currentVideoHolder) {
			return;
		}

		if (typeof this.currentVideo !== 'undefined') {
			this.currentVideoHolder.classList.remove('active');
			this.currentVideo.setVolume(0);
		}

		this.currentVideoHolder = holder;
		this.currentVideoHolder.classList.add('active');
		this.currentVideo = new Player(holder.querySelector('iframe'));
		if (this.begun) {
			this.currentVideo.play();
		}
		this.container.classList.remove('video-active-'+this.color);
		this.color = this.currentVideoHolder.dataset.color;
		this.container.classList.add('video-active-'+this.color);

		if (this.muted) {
			this.setVolume(0, false);
		} else {
			this.setVolume(this.volume, false);
		}

		if (forceDispatch || false) {
			this._dispatchColor();
		} else {
			this.dispatchColor();
		}
	}

	begin() {
		this.begun = true;
		this.container.querySelectorAll('.video').forEach(item => {
			(new Player(item.querySelector('iframe'))).play();
		});
		this.setVolume(this.defaultVolume);

		this.ensurePlay = setInterval(() => {
			this.currentVideo.getPaused()
				.then((paused) => {
					if (paused) {
						this.displayHelp()
					}
				})
				.catch((e) => {
					this.displayHelp();
				})
			;
			this.currentVideo.getVolume()
				.then((volume) => {
					if (volume === 0) {
						this.displayHelp();
					}
				})
				.catch((e) => {
					this.displayHelp();
				})
			;

			clearInterval(this.ensurePlay);
		}, 800);
	}

	displayHelp() {
		document.querySelector('.player-problem').classList.remove('hidden');
	}

	toggleMute() {
		if (this.muted) {
			this.setVolume((this.volume === 0) ? this.defaultVolume : this.volume, false);
			this.muted = false;
		} else {
			this.setVolume(0, false);
			this.muted = true;
		}
	}

	setVolume(volume, andSave) {
		if (typeof this.currentVideo === 'undefined') {
			return;
		}

		this.currentVideoHolder.querySelector('.volume-range').value = volume;
		if (typeof andSave === 'undefined' || andSave) {
			this.volume = volume;

			if (this.muted && volume > 0) {
				this.muted = false;
			}
		}

		let icon = this.currentVideoHolder.querySelector('i');
		icon.classList.remove('fa-volume-off');
		icon.classList.remove('fa-volume-mute');
		icon.classList.add((volume > 0) ? 'fa-volume-off' : 'fa-volume-mute');

		this.currentVideo.setVolume((volume >= 1) ? volume / 100 : volume).catch((e) => {
			this.displayHelp();
		});
	}

	dispatchColor() {
		this.debouncedDispatchColor();
	}

	_dispatchColor() {
		const self = this,
			data = new FormData();
		data.set('uid', this.uuid);
		data.set('color', this.color);

		axios.post('https://www.silent-stream.ch/api.php', data).then(function(response) {
			self.container.querySelector('.video[data-color="red"] .percentage-value').innerHTML = response.data.red.value;
			self.container.querySelector('.video[data-color="green"] .percentage-value').innerHTML = response.data.green.value;
			self.container.querySelector('.video[data-color="blue"] .percentage-value').innerHTML = response.data.blue.value;

			self.container.querySelector('.video[data-color="red"] .channel-title').innerHTML = response.data.red.channel;
			self.container.querySelector('.video[data-color="green"] .channel-title').innerHTML = response.data.green.channel;
			self.container.querySelector('.video[data-color="blue"] .channel-title').innerHTML = response.data.blue.channel;
		});
	}

	_stupidIosPlay() {
		const self = this;
		self.currentVideo.getPaused().then(function(paused) {
			if (!paused) {
				return;
			}

			self.currentVideo.play();
			clearInterval(self.stupidIosInterval);
		});
	}

	_fullScreenChange(element) {
		if (element === null) {
			// exit fullscreen
			this.container.querySelectorAll('.video').forEach(item => {
				(new Player(item.querySelector('iframe'))).play();
			});
		} else {
			this.container.querySelectorAll('.video:not(.active)').forEach(item => {
				(new Player(item.querySelector('iframe'))).pause();
			});
		}
	}

	fullscreen() {
		const iframe = this.currentVideoHolder.querySelector('iframe');

		if (BigScreen.enabled) {
			BigScreen.toggle(iframe);
		} else {
			if (this.currentVideo.requestFullscreen) {
				this.currentVideo.requestFullscreen();
			} else if (this.currentVideo.mozRequestFullScreen) {
				this.currentVideo.mozRequestFullScreen();
			} else if (this.currentVideo.webkitRequestFullscreen) {
				this.currentVideo.webkitRequestFullscreen();
			} else if (this.currentVideo.msRequestFullscreen) {
				this.currentVideo.msRequestFullscreen();
			}

			// dumb iOs will pause the video when leaving fullscreen
			// and not trigger any fullscreenchange event
			// so we start an interval to restart the video if paused!
			this.stupidIosInterval = setInterval(() => this._stupidIosPlay(), 1000);
		}
	}
}

export default VideoHolder
